<template>
	<div class="leaderboard-comp">
		<div class="leaderboard-item goods">
			<div class="item-header">
				<div class="left">
					<span class="iconfont icon-shopping-bag"></span>
					商品销售TOP排行榜
				</div>
				<!-- <div class="right" @click="jumpPage('goods', '', noPerm.goodsdata)">
					全部商品排行
					<Icon type="ios-arrow-forward" />
				</div> -->
			</div>
			<div class="table">
				<div class="theader">
					<ul>
						<li>排行</li>
						<li>商品</li>
						<li>品牌</li>
						<li>分类</li>
						<li>销售数量
							<!-- <kdx-hint-tooltip type="form" :max-width="340">
								<Form :label-width="60">
									<FormItem label="销售数量：">用户下单商品数量，不包含退款订单的商品数量</FormItem>
								</Form>
							</kdx-hint-tooltip> -->
						</li>
					</ul>
				</div>
				<div class="tbody">
					<div v-if="goodsList.length <= 0" class="no-data">
						暂无数据
					</div>
					<template v-else>
						<ul class="tr" v-for="(item, index) in goodsList" :key="index">
							<li>
								<svg v-if="index === 0" class="rank-icon" id="icon-paihang-jinpai" viewBox="0 0 1024 1024">
									<path d="M766.208 124.032H247.893333L358.229333 0h297.856l110.08 124.032z" fill="#CD3738"></path>
									<path d="M358.229333 0L247.893333 124.032l110.293334 334.762667 135.978666-67.626667L358.229333 0z"
										fill="#EE4A49"></path>
									<path d="M655.914667 0l110.293333 124.032-110.293333 334.762667-136.021334-67.626667L655.914667 0z"
										fill="#EF4B4A"></path>
									<path
										d="M518.4 328.533333l110.293333 81.493334 135.594667 20.266666 20.266667 135.594667 81.493333 110.336-81.493333 110.293333-20.266667 135.594667-135.594667 20.266667-110.336 81.536-110.293333-81.493334-135.594667-20.309333-20.266666-135.594667L170.666667 676.224l81.493333-110.293333 20.309333-135.637334 135.594667-20.266666L518.4 328.533333z"
										fill="#FFE135"></path>
									<path d="M518.4 908.117333a231.808 231.808 0 1 0 0-463.616 231.808 231.808 0 0 0 0 463.616z"
										fill="#EAB930"></path>
									<path
										d="M518.4 509.994667l61.397333 77.866666 93.098667 34.389334-55.04 82.645333-4.053333 99.114667-95.445334-26.88-95.402666 26.88-4.053334-99.114667-55.04-82.645333 93.098667-34.389334 61.44-77.866666z"
										fill="#FDE135"></path>
								</svg>
								<svg v-else-if="index === 1" class="rank-icon" id="icon-paihang-yinpai" viewBox="0 0 1024 1024">
									<path d="M766.208 124.032H247.893333L358.229333 0h297.856l110.08 124.032z" fill="#5460E6"></path>
									<path
										d="M358.229333 0L247.893333 124.032l110.293334 334.762667 135.978666-67.626667L358.229333 0zM655.914667 0l110.293333 124.032-110.293333 334.762667-136.021334-67.626667L655.914667 0z"
										fill="#748CFF"></path>
									<path
										d="M518.4 328.533333l110.293333 81.493334 135.594667 20.266666 20.266667 135.594667 81.493333 110.336-81.493333 110.293333-20.266667 135.594667-135.594667 20.266667-110.336 81.536-110.293333-81.493334-135.594667-20.309333-20.266666-135.594667L170.666667 676.224l81.493333-110.293333 20.309333-135.637334 135.594667-20.266666L518.4 328.533333z"
										fill="#D8DEE4"></path>
									<path d="M518.4 908.117333a231.808 231.808 0 1 0 0-463.616 231.808 231.808 0 0 0 0 463.616z"
										fill="#AEBAC3"></path>
									<path
										d="M518.4 509.994667l61.397333 77.866666 93.098667 34.389334-55.04 82.645333-4.053333 99.114667-95.445334-26.88-95.402666 26.88-4.053334-99.114667-55.04-82.645333 93.098667-34.389334 61.44-77.866666z"
										fill="#D8DEE4"></path>
								</svg>
								<svg v-else-if="index === 2" class="rank-icon" id="icon-paihang-tongpai" viewBox="0 0 1024 1024">
									<path d="M766.208 124.032H247.893333L358.229333 0h297.856l110.08 124.032z" fill="#8A3ACF"></path>
									<path
										d="M358.229333 0L247.893333 124.032l110.293334 334.762667 135.978666-67.626667L358.229333 0zM655.914667 0l110.293333 124.032-110.293333 334.762667-136.021334-67.626667L655.914667 0z"
										fill="#A64EFF"></path>
									<path
										d="M518.4 328.533333l110.293333 81.493334 135.594667 20.266666 20.266667 135.594667 81.493333 110.336-81.493333 110.293333-20.266667 135.594667-135.594667 20.266667-110.336 81.536-110.293333-81.493334-135.594667-20.309333-20.266666-135.594667L170.666667 676.224l81.493333-110.293333 20.309333-135.637334 135.594667-20.266666L518.4 328.533333z"
										fill="#F2A436"></path>
									<path d="M518.4 908.117333a231.808 231.808 0 1 0 0-463.616 231.808 231.808 0 0 0 0 463.616z"
										fill="#C47533"></path>
									<path
										d="M518.4 509.994667l61.397333 77.866666 93.098667 34.389334-55.04 82.645333-4.053333 99.114667-95.445334-26.88-95.402666 26.88-4.053334-99.114667-55.04-82.645333 93.098667-34.389334 61.44-77.866666z"
										fill="#F2A436"></path>
								</svg>
								<div class="text" v-else>{{ index + 1 }}</div>
							</li>
							<li>
								<div class="left">
									<img :src="$media(item.pic)" alt="" @error="replaceImage" />
								</div>
								<div class="right">
									<!-- <span class="tag" v-if="item.has_option === '1'">多</span> -->
									{{ item.title }}
								</div>
							</li>
							<li>
								<span v-for="item in item.brand" :key="item">{{ item }}</span>
							</li>
							<li>
								<span v-for="item in item.category" :key="item">{{ item }}</span>
							</li>
							<li>
								<span> {{ item.buyNum }} </span>件
							</li>
						</ul>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: [],
	data() {
		return {
			goodsList: [],
		};
	},

	created() {
		this.getGoodsRank();
	},
	methods: {
		// 商品销量TOP排行榜
		async getGoodsRank() {
			const result = await this.$api.home.rankinglist();
			this.goodsList = result;
		},
	},
};
</script>

<style scoped lang="scss">
.rank-icon {
	width: 30px;
	height: 30px;
}

.leaderboard-comp {
	display: flex;

	.leaderboard-item {
		flex: 1;
		background-color: #fff;
		border-radius: 2px;
		margin-right: 10px;
		padding-left: 20px;
		padding-right: 20px;
		width: 50%;

		&:last-child {
			margin-right: 0;
		}

		.item-header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-top: 20px;
			padding-bottom: 10px;

			.left {
				font-weight: bold;
				font-size: 14px;
				line-height: 20px;
				color: #262b30;
				display: flex;
				align-items: center;
				justify-content: center;

				.iconfont {
					margin-right: 6px;
				}

				.icon-shopping-bag {
					color: $brand-color;
				}

				.icon-database {
					color: #ff9900;
				}
			}

			.right {
				display: flex;
				align-items: center;
				font-size: 12px;
				line-height: 16px;
				color: $brand-color;
				cursor: pointer;
			}
		}

		.table {
			.tbody {
				ul {
					&:hover {
						background-color: $brand-hover;
					}
				}

				.no-data {
					padding: 10px;
					text-align: center;
				}
			}

			ul {
				display: flex;
				align-items: center;

				li:nth-child(1) {
					/*padding-left: 20px;*/
					width: 65px;
					flex-shrink: 0;
					text-align: center;
				}

				li:nth-child(2) {
					flex: 1;
				}

				li:nth-child(3) {
					width: 250px;
					flex-shrink: 0;
					text-align: center;
				}

				li:nth-child(4) {
					width: 250px;
					flex-shrink: 0;
					text-align: center;
				}

				li:nth-child(5) {
					padding-right: 20px;
					/* flex-shrink: 0; */
					flex:1;
					text-align: right;
				}
			}

			.theader {
				ul {
					height: 40px;
					font-weight: bold;
					font-size: 14px;
					line-height: 20px;
					color: #262b30;

					/deep/ .hint-tooltip {
						font-size: 14px;
						color: #939799;
						font-weight: normal;
					}
				}
			}

			.tbody {
				ul {
					padding-top: 10px;
					padding-bottom: 10px;
					border-top: 1px solid #e9edef;

					li:nth-child(1) {
						text-align: center;

						.text {
							font-weight: bold;
							font-size: 18px;
							line-height: 25px;
							color: #939799;
							padding-left: 5px;
						}

						.svg-icon {
							font-size: 28px;
						}
					}

					li:nth-child(2) {
						display: flex;
						align-items: center;
						flex: 1;

						.left {
							flex-shrink: 0;
							width: 48px;
							height: 48px;
							border: 1px solid #e9edef;
							box-sizing: border-box;
							border-radius: 2px;
							overflow: hidden;
							display: flex;
							align-items: center;
							justify-content: center;

							img {
								max-width: 100%;
								max-height: 100%;
							}
						}

						.right {
							padding-left: 10px;
							/*width: 330px;*/
							word-break: break-all;
							font-size: 14px;
							line-height: 20px;
							color: #262b30;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;

							.tag {
								padding: 1px 3px;
								background: #ffefe6;
								border-radius: 2px;
								font-size: 12px;
								line-height: 16px;
								color: #ed4014;
							}
						}
					}

					li:nth-child(3) {
						text-align: center;
						padding: 0 10px;

						span {
							border-radius: 2px;
							background-color: $background-color;
							@include font-12-16;
							margin-right: 10px;
							color: $text-second;
							display: inline-block;
							height: 20px;
							line-height: 20px;
							padding: 0 8px;

							&:last-child {
								margin-right: 0;
							}
						}
					}

					li:nth-child(4) {
						text-align: center;
						padding: 0 10px;

						span {
							border-radius: 2px;
							background-color: $background-color;
							@include font-12-16;
							margin-right: 10px;
							color: $text-second;
							display: inline-block;
							height: 20px;
							line-height: 20px;
							padding: 0 8px;

							&:last-child {
								margin-right: 0;
							}
						}
					}

					li:nth-child(5) {
						display: flex;
						align-items: center;
						font-size: 14px;
						line-height: 20px;
						color: #262b30;
						flex: 1;
						justify-content: flex-end;

						span {
							font-weight: bold;
							font-size: 18px;
							line-height: 25px;
							color: #636669;
							margin-right: 8px;
						}
					}
				}
			}
		}

		&.user {
			.table {
				.tbody {
					ul {
						ul {
							&:hover {
								background-color: $brand-hover;
							}
						}

						li:nth-child(2) {
							.left {
								width: 48px;
								height: 48px;
								border-radius: 50%;
								border: 1px solid $border-color;
								box-sizing: border-box;
								overflow: hidden;

								img {
									max-width: 100%;
									max-height: 100%;
									border-radius: 50%;
								}
							}

							.right {
								.name {
									color: $brand-color;
									cursor: pointer;
								}

								.icon {
									margin-top: 4px;

									.iconfont {
										font-size: 20px;

										&.icon-H {
											color: #ff6004;
										}

										&.icon-weixin {
											color: #1bb723;
										}

										&.icon-weixinxiaochengxu {
											color: #677de0;
										}

										&.icon-zhifubaoxiaochengxu {
											color: #2094e7;
										}

										&.icon-douyin {
											color: #1f0a1c;
										}
									}
								}
							}
						}

						li:nth-child(3) {
							font-weight: bold;
							font-size: 14px;
							line-height: 20px;
							color: #636669;

							span {
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}
}</style>
